<template>
  <div id="app" class="principal justify-content-center">
    <div id="block_container" class="d-flex justify-content-center contenedor ">
      <div class=" contenedor">
        <div class="card text-center  ">
          <div class="card-body text-secondary login-principal">
            <img
              class="card-img-top"
              src="../images/login.png"
              alt="Card image cap"
            />
       
            <form name="form" @submit.prevent="handleLogin">
              <div class="form-group mt-3">
                <input
                  v-model="user.username"
                  v-validate="'required'"
                  type="text"
                  class="form-control texfield-formulario"
                  name="username"
                  :placeholder="$t('loginuser')"
                />
                <div
                  v-if="errors.has('username')"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{$t('loginusermessage')}}
                </div>
              </div>
              <div class="form-group mb-5">
                <input
                  v-model="user.password"
                  v-validate="'required'"
                  type="password"
                  class="form-control texfield-formulario"
                  name="password"
                  :placeholder="$t('password')"
                />
                <div
                  v-if="errors.has('password')"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{$t('passwordmessage')}}
                </div>
              </div>
              <div class="form-group">
                <button
                  variant="outline-success"
                  class="btn-login"
                  :disabled="loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm "
                  ></span>
                  <span>LOG IN</span>
                </button>
              </div>

              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
              <router-link to="/registro" class="nav-link"
                ><font-awesome-icon icon="user-plus" />  {{$t('register')}}</router-link
              >
              <router-link to="/cambiopassword" class="nav-link">
                {{$t('forgot')}}</router-link
              >
            </form>
          </div>
          <b-row>
              <b-col style="text-align: end;">
                 <a href="#" @click.prevent="changeLanguage('en')"> <img
                      src="../images/icons8-english-96.png" style="width: 40px;"
                  /></a>
              </b-col>
              <b-col style="text-align: start;">
                 <a href="#" @click.prevent="changeLanguage('es')">
                  <img
                      src="../images/icons8-spain-96.png" style="width: 40px;"
                  /></a>
              </b-col>
            </b-row>
        </div>
      </div>
    </div>
    <div id="block_container" class="d-flex justify-content-center  contenedor">
      <div>
        <p class="image-logo">
          <img src="../images/logo_transparente.png" width="300px" />
        </p>
        <p class="texto-logo">
          {{$t('mendelmessage')}}
        </p>
        <p class="texto-pie">
          Made with ♥ by Mendel’s Brain S.L.
        </p>
        <hr />
        <center>
          <p style="color: #ffffff">
            <a
              href="https://mendelsbrain.com/aviso-legal/"
              style="color: #ffffff"
              >{{$t('terms')}}</a
            >
            |
            <a
              href="https://mendelsbrain.com/politica-de-privacidad/"
              style="color: #ffffff"
              >{{$t('privacy')}}</a
            >
            |
            <a
              href="https://mendelsbrain.com/politica-de-cookies/"
              style="color: #ffffff"
              >{{$t('cookies')}}</a
            >
            |
            <a
              href="https://mendelsbrain.com/consentimiento-informado/"
              style="color: #ffffff"
              >{{$t('consent')}}</a
            >
          </p>
          <p>
            <img
              class="etiqueta-ce"
              src="../images/ce_simple_white.png"
              alt="Certificado CE"
            />
          </p>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/resultados');
    }
  },
  methods: {
    changeLanguage(lang)
    {
      this.$parent.changeLanguage(lang);
    },
    handleLogin() {
      //console.log'Login');
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/resultados');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.texto-logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
  font-size: 20px;
}
.image-logo {
  text-align: center;
}

.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
label {
  display: block;
  margin-top: 10px;
}
.btn-login {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-login:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.texfield-formulario {
  background-color: #ffffff;
  border-radius: 10px;
}
input::placeholder {
  color: #bbbbbb;
  font-size: 1em;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  max-width: 800px;
  background-color: #ffffff;
  padding: 20px 30px 20px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.profile-img-card {
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.nav-link {
  color: #aaaaaa;
}
.contenedor {
  margin: 0px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 900px) {
  .contenedor {
    margin: 0px 0px 0px 0px;
    padding: 4px 4px 4px 4px;

    text-align: center;
  }
  .texto-pie {
    font-size: 10px;
    text-align: center;
  }
  .texto-logo {
    font-size: 10px;
  }
}
</style>
