<template>
  <div class="main">
    <div>
      <b-navbar
        class="menu-principal"
        style="font-size: 120%;"
        toggleable="md"
        navbar-expand-sm
        sticky
        v-if="currentUser"
      >
        <b-navbar-brand href="#">
          <router-link 
            v-if="estadoRes === 4"
            @click.native="recargar"
            to="/resultados"
            exact-path
            ><img
              src="./images/logo_mendels_brain.png"
              class="d-inline-block align-top"
              alt="Kitten"
              width="300"
            />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="ml-5">
          <b-navbar-nav>
            <router-link v-if="estadoRes != 4 && this.$store.state.auth.user == 'usuario'" to="/inicio" class="nav-link ml-2 menu"
              >Inicio</router-link
            >
            <router-link
              v-if="estadoRes === 4"
              @click.native="recargar"
              to="/resultados"
              exact-path
              class="nav-link ml-2 menu"
              > {{$t('informe')}}</router-link
            >
          </b-navbar-nav>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <a class="nav-link menu" href @click.prevent="logOut">
              {{$t('cerrar_sesion')}} <i class="fas fa-chevron-right"></i>
            </a>
          </li>
        </b-navbar-nav>
      </b-navbar>
    </div>

    <router-view />

  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      estadoRes: 0,
      detalle: 0,
      language: 'ESP'
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$i18n.fallbackLocale = lang;
      if(lang == 'en'){ this.language = 'ENG'}
      if(lang == 'es'){ this.language = 'ESP'}
    },
    recargar() {
      this.detalle = 0;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    validate() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .get('https://api.mendelbrain.com:55010/api/mendel/auth/validate', {
          headers: {
            Authorization: 'Bearer ' + user.accessToken
          }
        })
        .then(response => {
          console.log('ok' + response);
        })
        .catch(error => {
          console.log(error.response.status);
          if (error.response.status === 403) {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        });
    }
  },
  mounted() {
    this.validate();
  }
};
</script>

<style scoped>
@import './fonts/style.css';
.principal {
  background: rgb(140, 175, 54);
  background: radial-gradient(
    circle,
    rgba(140, 175, 54, 1) 0%,
    rgba(134, 173, 53, 1) 42%,
    rgba(77, 159, 44, 1) 76%,
    rgba(57, 154, 41, 1) 90%
  );
  min-height: 100vh;
  scrollbar-width: 5px;
}
.image-logo {
  text-align: center;
}
.texto-logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: 20px;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
@media (min-width: 320px) and (max-width: 900px) {
  .menu {
    font-size: 14px;
  }
}
</style>
