import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 1000,
    laptop: 1250,
    mpequeño:1410,
    desktop: Infinity,
  }
})
Vue.use(Vuex);

Vue.mixin({
  data: function() {
    return {
      get globalReadOnlyProperty() {
        return "Can't change me!";
      }
    }
  }
})

export default new Vuex.Store({
  modules: {
    auth
  },
  devtool: 'source-map'
});
