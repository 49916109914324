// eslint-disable-next-line no-unused-vars
const messages =

{
    'en': {
        loginuser: 'Username or e-mail address',
        loginusermessage: 'User required',
        password: 'Password',
        passwordmessage: 'Password required',
        register: 'Register',
        forgot: 'Forgot your password?',
        mendelmessage: 'Mendel Brain moves forward to bring the genetics of personality to inquiring minds',
        terms: 'Terms of use',
        privacy: 'Privacy policy',
        cookies: 'Cookies',
        consent: 'Informed consent',
        passwordchange: 'Enter your email address and you will be sent a link to change your password.',
        emailaddress: 'Your email address',
        sendlink: 'SEND LINK',
        instruction: 'Follow the instructions in the email to change your password',
        errormail: 'The email address is not a valid address',
        checkmail: 'Checking mail and sending instructions',
        backlogin: 'Back to login',
        kit: 'Kit code',
        firstname: 'First name',
        surname: 'Surname',
        birth: 'Date of birth',
        sex: 'Sex',
        male: 'Male',
        female: 'Female',
        telephone: 'Telephone',
        address: 'Address',
        province: 'Province',
        town: 'Town',
        postal: 'Postal code',
        country: 'Country',
        confirm: 'Confirm password',
        accepterms: 'Accept terms of use',
        acceptconsent: 'I have read, understood and agree to the informed consent form',
        acceptupdates: 'I wish to receive updates, news and special offers',
        acceptcontribute: 'I WANT TO CONTRIBUTE TO THE ADVANCEMENT OF THE SCIENCES OF HUMAN PSYCHOLOGY AND I ACCEPT THE TRANSFER OF ANONYMISED DATA FOR RESEARCH USE.',
        mostpredisposed: 'Most predisposed characteristics',
        lesspredisposed: 'Less predisposed characteristics',
        estimatedtime4: 'Estimated time for receipt of sample: 4 days',
        registertext1: 'You have successfully registered your DNA kit. We count that you have sent the sample to our offices using the envelope included in the DNA Kit. If not, please send us the tube with the saliva using the envelope so that we can analyse it as soon as possible. If you have problems with the the sample, please contact us at info@mendelbrain.com and we will help you to solve it.',
        notreceived: 'We have not yet received the saliva sample at our office',
        registertext2: 'If you have not sent it, please send it to us as soon as possible so that the DNA does not deteriorate and we can generate the report as soon as possible. If you have sent it, please contact us at info@mendelbrain.com so that we can look for the sample or send you a new kit so that you can obtain your report without complications.',
        estimatedtime10: 'Estimated time for receipt in the laboratory: 10 days.',
        registertext3: 'Your saliva sample is being flown to the Eurofins laboratory Megalab Denmark, where 700,000 genetic variants will be sequenced using Illumina .',
        estimatedtime20: 'Estimated time for sample sequencing: 20 days',
        registertext4: 'Your saliva sample has arrived at Eurofins Megalab Denmark.',
        close: 'Close',
        adultdni: 'Adult DNI',
        youngerdni: 'Younger DNI',
        passwordminimun: 'Password is required and must be a minimum of 6 characters',
        passwordmatch: 'Passwords do not match',
        informedconsent: 'Informed consent',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        acceptprofessional1: 'Autorizo a ',
        acceptprofessional2: ' a visualizar mis resultados',
        userType: 'User type',
        informe:'Report',
        cerrar_sesion:'Log out',
        todos:'All',
        psicologia:'Psychology',
        capacidades: 'Capacities',
        sueño:'Sleep',
        adicciones:'Addictions',
        curiosidades:'Curiosities',
        descargar_adn_raw:'Download RAW DNA',
        descargar_pdf:'Download PDF',
        tus_variantes:'Your genetic variants analysed',
        variantes_analizadas: 'Genetic variants analysed',
        variantes_positivo: 'Genetic variants with positive effect',
        variantes_negativo: 'Genetic variants with negative effect',
        variantes_no_efecto: 'Genetic variants with no effect',
        ver_variantes:'See my genetic variants',
        heredabilidad_rasgo:'Heritability of the trait',
        aportacion_genetica:'Contribution of genetics',
        aportacion_otros_factores:'Contribution of other factors',
        referencias_psicologicas:'Psychological references',
        referencias_geneticas:'Genetic references',
        listado_pacientes:'List of patients of',
        tu_puntuacion: 'Your genetic score',
        cromosoma:'Chromosome',
        posicion:'Position',
        info_text_1: 'Most of the traits that define our psychology are influenced by many different factors, such as genetics, lifestyle and environment.',
        info_text_2: 'In general, a statistical model using many factors provides better predictions than looking at individual factors on their own. To develop our models, we first identify genetic markers associated with a trait using data from hundreds of scientific papers studying the genetics of human psychology. We then use statistical methods to generate a score for that trait using its genotype at the relevant genetic markers.',
        info_text_3: 'The accuracy of these predictions varies from trait to trait depending on the depth of the study used.' ,
        info_titulo: 'Did you know that?',
        info_titulo2: 'Risk Score',
        info_titulo3: 'Polygenic Risk Score',
        info_text_4:'This is the cut-off score obtained in psychological tests to determine the normative determine the normative score of the population on this variable. A higher score above this cutoff implies unusual development of the characteristic. development of the characteristic. This is the cut-off score obtained in psychological tests to determine the populations normative score on this variable. of the population on this variable. A higher score above this cutoff higher than this cutoff implies unusual development of the trait.',
        info_text_5:'',
        referencias: 'References',
        caracteristicas:'Features',
        volver_principal: 'Back to main'    
    },
    'es': {
        loginuser: 'Usuario o correo electrónico',
        loginusermessage: 'Usuario obligatorio',
        password: 'Contraseña',
        passwordmessage: 'Contraseña obligatoria',
        register: 'Registrar',
        forgot: '¿Has olvidado tu contraseña?',
        mendelmessage: 'Mendel Brain avanza con el fin de acercar la genética de la personalidad a las mentes más curiosas',
        terms: 'Términos legales',
        privacy: 'Política de privacidad',
        cookies: 'Cookies',
        consent: 'Consentimiento informado',
        passwordchange: 'Escriba su correo electrónico y se le enviará un enlace para poder cambiar su contraseña',
        emailaddress: 'Correo electrónico',
        sendlink: 'ENVIAR ENLACE',
        instruction: 'Siga las instrucciones indicadas en el correo para cambiar su contraseña',
        errormail: 'La dirección de correo no es una dirección válida',
        checkmail: 'Comprobando correo y envío de instrucciones',
        backlogin: 'Volver a login',
        kit: 'Código del kit',
        firstname: 'Nombre',
        surname: 'Apellidos',
        birth: 'Fecha de nacimiento',
        sex: 'Sexo',
        male: 'Hombre',
        female: 'Mujer',
        telephone: 'Teléfono',
        address: 'Dirección',
        province: 'Provincia',
        town: 'Población',
        postal: 'C.postal',
        country: 'País',
        confirm: 'Confirmar contraseña',
        accepterms: 'Aceptar términos de uso',
        acceptconsent: 'He leído, entendido y acepto el consentimiento informado',
        acceptupdates: 'Deseo recibir actualizaciones, novedades y ofertas especiales',
        acceptcontribute: 'QUIERO CONTRIBUIR AL AVANCE DE LAS CIENCIAS DE LA psicología humana Y ACEPTO LA CESIÓN DE DATOS ANONIMIZADOS PARA SU USO EN INVESTIGACIÓN',
        mostpredisposed: 'Características con mayor predisposición',
        lesspredisposed: 'Características con menor predisposición',
        estimatedtime4: 'Tiempo estimado para la recepción de la muestra: 4 días',
        registertext1: 'Has registrado el Kit de ADN con éxito. Contamos con que has enviado la muestra a nuestras oficinas utilizando el sobre que incluye el Kit de ADN. De no ser así envíanos el tubo con la saliva utilizando el sobre para que podamos analizarla lo antes posible. Si tienes problemas con el envío de la muestra ponte en contacto con nosotros a través de info@mendelbrain.com y te ayudaremos a resolverlo.',
        notreceived: 'Aún no hemos recibido la muestra de saliva en nuestra oficina',
        registertext2: 'Si no la has enviado, por favor, envíala tan pronto como puedas para que el ADN no se deteriore y podamos generar el informe lo antes posible. Si la has enviado ponte en contacto con nosotros a través de info@mendelbrain.com para que localicemos la muestra o te enviemos un nuevo kit para que puedas obtener tu informe sin complicaciones.',
        estimatedtime10: 'Tiempo estimado para la recepción en el laboratorio: 10 días',
        registertext3: 'Tu muestra de saliva está volando al laboratorio Eurofins Megalab Denmark, donde se secuenciarán 700.000 variantes genéticas utilizando la tecnología Illumina.',
        estimatedtime20: 'Tiempo estimado para la secuenciación de la muestra: 20 días',
        registertext4: 'Tu muestra de saliva ha llegado al laboratorio Eurofins Megalab Denmark.',
        close: 'Cerrar',
        adultdni: 'DNI Adulto',
        youngerdni: 'DNI menor',
        passwordminimun: 'La contraseña es requerida y debe tener un mínimo de 6 caracteres',
        passwordmatch: 'Las contraseñas no coinciden',
        informedconsent: 'Consentimiento informado',
        day: 'Día',
        month: 'Mes',
        year: 'Año',
        acceptprofessional1: 'Autorizo a ',
        acceptprofessional2: ' a visualizar mis resultados',
        userType: 'Tipo de usuario',
        razon: 'Razón social',
        entidadBancaria: 'Entidad bancaria',
        iban: 'IBAN',
        informe:'informe',
        cerrar_sesion:'Cerrar Sesion',
        todos:'Todos',
        psicologia:'Psicologia',
        capacidades: 'Capacidades',
        sueño:'Sueño',
        adicciones:'Adicciones',
        curiosidades:'Curiosidades',
        descargar_adn_raw:'Descargar ADN RAW',
        descargar_pdf:'Descargar PDF',
        tus_variantes:'Tus variantes ghenéticas analizadas',
        variantes_analizadas: 'Variantes genéticas analizadas',
        variantes_positivo: 'Variantes genéticas con efecto positivo',
        variantes_negativo: 'Variantes genéticas con efecto negativo',
        variantes_no_efecto: 'Variantes genéticas sin efecto',
        ver_variantes:'Ver mis variantes genéticas',
        heredabilidad_rasgo:'Heredabilidad del rasgo',
        aportacion_genetica:'Aportación de la genética',
        aportacion_otros_factores:'Aportación de otros factores',
        referencias_psicologicas:'Referencias Psicológicas',
        referencias_geneticas:'Referencias genéticas',
        listado_pacientes:'Listado de pacientes de',
        tu_puntuacion: 'Tu puntuación genética',
        cromosoma:'Cromosoma',
        posicion:'Posicion',
        info_text_1:'La mayoría de los rasgos que definen nuestra psicología están influenciados por múltiples factores diferentes, como la genética, estilo de vida y medio ambiente.',
        info_text_2: 'Por lo general, un modelo estadístico que usa muchos factores proporciona mejores predicciones que mirar factores individuales por si mismos. Para desarrollar nuestros modelos, primero identificamos marcadores genéticos asociados con un rasgo utilizando datos de centenares de artículos cientificos que estudian la genética de la psicología humana. Luego usamos métodos estadísticos para generar un puntuaje o score para ese rasgo utilizando su gentipo en los marcadores genéticos relevantes.',
        info_text_3: 'La precisión de estas predicciones varía de un rasgo a otro en función de la profundidad del estudio utilizado.' ,
        info_titulo: 'Sabías qué?',
        info_titulo2: 'Puntuación de Riesgo',
        info_titulo3: 'Puntuación Riesgo Poligénico',
        info_text_4:'Este es el límite de puntuación obtenido en test psicológicos para determinar la puntuación normativa de la población en esta variable. Una mayor puntuación mayor de este límite implica el desarrollo poco habitual de la característica. Este es el límite de puntuación obtenido en test psicológicos para determinar la puntuación normativa de la población en esta variable. Una mayor puntuación mayor de este límite implica el desarrollo poco habitual de la característica.',
        info_text_5:'Esta puntuación indica tu riesgo poligénico para el rasgo mostrado. Se trata de un cálculo realizado en base a las variantes genéticas que posees y su frecuencia en la población. Cuanto mayor es este índice mayor predisposición poseemos al rasgo y, cuanto menor es, menor predisposición poseemos. Esta puntuación indica tu riesgo poligénico para el rasgo mostrado. Se trata de un cálculo realizado en base a las variantes genéticas que posees y su frecuencia en la población. Cuanto mayor es este índice mayor predisposición poseemos al rasgo y, cuanto menor es, menor predisposición poseemos.',
        referencias: 'Referencias',
        caracteristicas:'Características',
        volver_principal: 'Volver a principal'    
    }



};

export { messages };