import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

import VueI18n from 'vue-i18n';
import { messages } from './plugins/Translation'
Vue.use(VueI18n);

import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.config.productionTip = false;

Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component("v-select", vSelect);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuex);

const i18n = new VueI18n({ locale: 'es', fallbackLocale: 'es', messages });

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app');
